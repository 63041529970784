<template>
  <UiInput
    v-model="inputValue"
    :name="name"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :type="inputType"
    :disabled="disabled"
    :hint="hint"
    :floating-label="floatingLabel"
    :size="size"
    :label="label"
  >
    <template #append>
      <UiButton variant-type="icon" variant="clear" @click="toggleInputType">
        <UiIcon
          :name="inputType === 'password' ? 'eye' : 'eye-crossed'"
          class="size-5"
        />
      </UiButton>
    </template>
  </UiInput>
</template>

<script setup lang="ts">
import { InputVariants } from "~/components/Ui/input/variant";

defineProps<{
  autocomplete?: string;
  placeholder?: string;
  name: string;
  disabled?: boolean;
  hint?: string;
  floatingLabel?: boolean;
  size?: InputVariants["size"];
  label?: string;
}>();

type inputTypeT = "password" | "text";

const inputValue = defineModel<string>();
const inputType = ref<inputTypeT>("password");

function toggleInputType() {
  inputType.value = inputType.value === "password" ? "text" : "password";
}
</script>
